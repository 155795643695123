.popular-header {
  width: 100%;
  height: 56px;
  background: #c2114b;
}
.popular-header .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topSpace {
  margin-top: 64px;
}
.profession-creator-wrapper {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.brand-filter-section .inpWid, .brand-filter-section .filter-wrapper .keyword-wrapper .creators-filter-select {
  width: calc(100% - 100px);
}
.brand-filter-section .inpWid .rangeslider-track {
  margin: 0 15px;
  width: 100%;
}
.header-title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px;
}
.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-menu > div {
  padding-right: 15px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}
.header-menu > div:nth-child(1) {
  padding-right: 15px;
  border-right: 1px solid #fff;
}
.header-menu > div:nth-child(2) {
  padding-left: 15px;
}
.filter-section {
  padding: 60px 0;
  background-color: #f8f8f8;
  justify-content: center;
}
.filter-btn {
  width: 100%;
  height: 50px;
  border-radius: 11px;
  background: #000;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 15px;
}
.filter-btn-wrapper {
  display: flex;
}
.search-words-section {
  display: flex;
  justify-content: space-between;
}
.search-history {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  padding: 6px;
  background-color: #e9ecef;
  border-radius: 6px;
}
.cancel-icon {
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding-left: 4px;
}
.searched-word-wrapper {
  display: flex;
  align-items: center;
  background-color: #c2114b;
  font-size: 14px;
  cursor: pointer;
  padding: 0 6px;
  margin: 0 6px;
}
.filter-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 0.24) 0 3px 8px;
  border-radius: 8px;
  padding: 20px;
}
.filter-content {
  display: block;
}
.filter-items {
  padding: 8px 0;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.brand-filter-section .filter-items {
  width: 100px;
}
.keyword-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px 0;
}
.profession-wrapper {
  border-radius: 5px;
  outline: none !important;
  background: #fff0;
  padding-bottom: 10px;
}
.filter-input-wrapper {
  border-radius: 5px;
  border: 0.5px solid #ced4da;
  background: #fff0;
  width: 70%;
  height: 39px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.search-icon {
  padding: 4px 8px;
  background-color: #c2114b;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 4px;
  color: #fff;
  font-weight: 600;
}
.keyword-input {
  border: none;
  outline: none;
  padding-left: 10px;
  width: 100%;
  font-size: 14px;
}
.form-select,
.range-inputs,
.select__control.css-1oeearl-control,
.css-13cymwt-control,
.css-13cymwt-control:hover,
.css-t3ipsp-control,
.css-13cymwt-control {
  outline: 0;
  font-size: 14px;
  height: 45px !important;
  min-height: 45px !important;
}
.creator-age-main input.form-control.range-inputs {
  height: 45px !important;
  min-height: 35px !important;
  padding: 5px 0 5px 5px;
  border: 1px solid #ced4da !important;
  outline: 0 !important;
  width: 48.5%;
  font-family: sans-serif;
  font-size: 14px;
}
.range-inputs::placeholder {
  font-family: sans-serif !important;
  font-size: 14px !important;
  color: #808080 !important;
}
.keyword-input::placeholder {
  font-family: sans-serif !important;
  font-size: 14px !important;
  color: #808080 !important;
}
.select-placeholders::placeholder {
  font-family: sans-serif !important;
  font-size: 14px !important;
  color: #808080 !important;
}
.keyword-wrapper.creator-age-main .creator-age-wrapper {
  justify-content: space-between;
}

.select__control.css-1oeearl-control {
  height: auto !important;
}
.check-section {
  display: flex;
  justify-content: space-evenly;
}
.label-style {
  color: #000;
  font-size: 15px;
  font-weight: 300;
  padding-left: 10px;
}
.input-items-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.input-items-style {
  width: 137px;
  min-height: 38px;
  background-color: hsl(0 0% 100%);
  border-color: hsl(0 0% 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.input-items-style::placeholder {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.input-country-style {
  width: 100%;
  height: 35px;
  border-radius: 7px;
  border: 1px solid #000;
  background: #fff;
}
.category-chips {
  padding: 20px 0 0 15px;
}
.each-category {
  padding: 8px 0;
}
.submit-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 5px;
}
.reset-btn,
.search-btn {
  width: auto;
  height: 40px;
  border-radius: 60px;
  background: linear-gradient(90deg, #030303 6.98%, #000 65.92%);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  padding: 5px 35px;
  min-width: 100px;
}
.search-btn {
  background: #c2114b;
  margin-left: 15px;
}
.models-images {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 11%) 0 2px 8px;
  border-radius: 8px;
  padding: 15px;
}
.range-inputs {
  cursor: unset;
}
.modalSpc {
  width: auto;
  padding: 0;
}
.models-images .gallery-main .gallery-wrapper.w-100 {
  width: 100%;
  margin: 10px 0;
}
.find-creator-gallery-main {
  display: block !important;
}
.find-talent-image {
  width: 100%;
  height: 250px;
  border-radius: 8px;
}
.models-images .user-details .location-wrapper > img {
  filter: inherit;
  width: 13px;
  height: auto;
}
.favoruite-find-talent {
  position: absolute;
  top: 10px;
  right: 10px;
}
.profession-creator-wrapper .css-1xc3v61-indicatorContainer {
  padding: 0;
}
.profession-creator-wrapper .css-3w2yfm-ValueContainer {
  padding: 2px 5px;
}
.find-talentrating {
  position: absolute;
  background-color: #fff;
  border-radius: 30px;
  padding: 4px;
  top: 220px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.find-talentrating > img {
  width: 8px;
  height: 8px;
  margin: 0 2px;
}
select {
  cursor: pointer;
}
.select__multi-value {
  background-color: #c2114b !important;
  color: #fff !important;
}
.select__multi-value__label {
  color: #fff !important;
}
.creator-age-wrapper {
  display: flex;
  justify-content: space-between;
}
.posRel {
  position: relative;
}
.creator-age-main {
  justify-content: end;
  padding-top: 0;
}
.find-creator-name {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 6px 0;
  text-transform: capitalize;
  cursor: pointer;
}
.models-images .heart-icon {
  width: 17px !important;
  height: auto !important;
}
.models-images .gallery-img {
  height: 210px;
}
.imgBox {
  position: relative;
}
.models-images .gallery-main {
  margin: 0;
}
.models-images .gallery-main {
  margin: 0;
}

.kids-form-section .creator-age-wrapper .age-inputs{ 
  width: calc(98% / 2) !important;
  
}

.galCont {
  cursor: pointer;
}
.find-creator-address {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 3px 0 6px;
}
.models-images .user-details .location-wrapper:last-child {
  padding: 0;
}
.find-creator-location-name {
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  padding: 0 0 0 5px;
}
.keyword-input::placeholder {
  color: #000;
}
.selected-word-style {
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 14px;
}
.profession-creator-wrapper .css-wsp0cs-MultiValueGeneric {
  font-size: 11px;
  padding: 2px 0 2px 3px !important;
}



@media (max-width: 1080px) {

  .brand-filter-section .inpWid, .brand-filter-section .filter-items {
    width: 100%;
    margin: 10px 0 0;
  }
  
}
@media (min-width: 991px) and (max-width: 1300px) {
  .col-md-5.col-lg-4.leftSides.pr-0 {
    width: 36.333333%;
  }
  .col-md-7.col-lg-8.rightSides {
    width: 63.666667%;
  }
  .rightSides .gallery-main .col-lg-3 {
    width: 50%;
  }
}
@media (min-width: 769px) and (max-width: 1080px) {
  .filter-wrapper {
    padding: 15px;
  }
  .brand-filter-section .filter-items, .brand-filter-section .inpWid, .brand-filter-section .filter-wrapper .keyword-wrapper .creators-filter-select {
    width: 100%;
    padding: 0;
    margin: 0 0 3px;
  }
  .brand-filter-section .inpWid {
    width: 100%;
  }
  .profession-creator-wrapper,
  .keyword-wrapper {
    flex-direction: column;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .filter-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .filtered-gallery {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .filter-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 20px;
  }
  .models-images {
    width: 100%;
  }
  .talents-profession {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .header-menu > div:nth-child(2) {
    padding-left: 8px;
  }
  .popular-header {
    height: auto;
  }
  .header-menu > div:nth-child(1) {
    padding-right: 8px;
  }
  .popular-header .header-title {
    margin: 15px 0 10px;
  }
  .popular-header .header-menu {
    margin: 0 0 10px;
  }
  .popular-header .container {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: column;
  }
  .favTalent .col-md-4.col-lg-3.px-1 {
    width: 50%;
  }
  .models-images {
    width: 100% !important;
    float: left;
    margin: 5px 0 20px;
  }
  .keyword-wrapper,
  .profession-creator-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: left;
    align-items: inherit;
    margin: 0 0 15px;
    float: left;
    width: 100%;
  }
  .filter-wrapper {
    width: 100% !important;
    padding: 0;
    box-shadow: inherit;
    margin: 0 0 20px;
  }
  .brand-filter-section .filter-items,
  .brand-filter-section .inpWid, .brand-filter-section .inpWid, .brand-filter-section .filter-wrapper .keyword-wrapper .creators-filter-select {
    width: 100%;
    margin: 0;
  }
 .brand-filter-section .filter-wrapper .keyword-wrapper .creators-filter-select.creators-filter-select-range {
    width: 100%;
    margin: 10px 0 0 0;
  }
  .brand-filter-section .filter-items {
    padding: 2px 0;
  }
  .leftSides.pr-0 {
    padding: 0 10px;
  }
  .brand-filter-section {
    padding: 0 !important;
  }
  .rightSides {
    padding: 0;
    margin: 15px 0 0 0;
    border-top: 1px solid #ddd;
  }
  .rightSides .models-images {
    padding: 0;
    box-shadow: inherit;
  }
}
@media (max-width: 480px) {

  .brand-filter-section .filter-input-wrapper  .search-icon {
    padding: 3px 8px;
  }
  .search-icon {
    padding: 10px;
  }
  .creator-age-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .header-title {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .header-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-menu > div {
    padding-right: 15px;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
  }
  .header-menu > div:nth-child(1) {
    padding-right: 15px;
    border-right: 1px solid #fff;
  }
  .header-menu > div:nth-child(2) {
    padding-left: 15px;
  }
  .filter-section {
    margin-top: 20px;
    padding: 10px 0;
  }
  .filter-content {
    display: block;
  }
  .filter-input-wrapper {
    border-radius: 5px;
    border: 0.5px solid #ced4da;
    background: #fff0;
    height: 39px;
    flex-shrink: 0;
    display: flex;
    cursor: pointer;
    margin: 10px 15px;
    align-items: center;
  }
  .keyword-input {
    border: none;
    outline: none;
  }
  .search-words-section {
    width: 100%;
  }
  .search-history {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .search-history > div {
    padding: 8px 8px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    cursor: pointer;
  }
  .input-items-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .input-items-style {
    width: 137px;
    min-height: 38px;
    background-color: hsl(0 0% 100%);
    border-color: hsl(0 0% 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }
  .input-items-style::placeholder {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .category-chips {
    padding: 20px 0 0 15px;
  }
  .each-category {
    padding: 8px 0;
  }
  .submit-buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  .reset-btn,
  .search-btn {
    width: 86px;
    height: 40px;
    border-radius: 140px;
    background: linear-gradient(90deg, #030303 6.98%, #000 65.92%);
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .search-btn {
    background: #c2114b;
    margin-left: 15px;
  }
  .creators-filter-select-range {
    margin: 20px 0;
  }
  .favTalent .col-md-4.col-lg-3.px-1 {
    width: 100%;
  }
}
.job-company_dtls {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding-right: 5px;
}
